import axios from 'axios';

export const login = async (username, password) => {
    try {
        const response = await axios.post('/ghwdiotzii/backend/api/login/', {
            username,
            password
        });
        const { token, user } = response.data;
        console.log(user);
        localStorage.setItem('token', token);
        return user;
    } catch (error) {
        console.error("Login error", error);
        throw error;
    }
};

/*export const register = async (username, email, password) => {
    try {
        const response = await axios.post('http://127.0.0.1:8000/api/register/', {
            username,
            email,
            password
        });
        const { token, user } = response.data;
        localStorage.setItem('token', token);
        return user;
    } catch (error) {
        console.error("Register error", error);
        throw error;
    }
};*/

export const logout = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error("No token found");
        }

        await axios.post('/ghwdiotzii/backend/api/logout/', {}, {
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            }

        });
        localStorage.removeItem('token');
    } catch (error) {
        console.error("Logout error", error);
        throw error;
    }
};
