import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import './loginarea.css';
import { Link } from 'react-router-dom'; 
import Sidebar from '../../Sidebar';
import { login } from './auth';
import Topbarprivat from './topbarprivat.js';

function EmailView() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        checkLoginStatus();
    }, []);

    const checkLoginStatus = () => {
        const token = localStorage.getItem('token');
        console.log("Token"+token);
        if (token) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    };

    useEffect(() => {
        axios.interceptors.request.use(
            config => {
                const token = localStorage.getItem('token');
                if (token) {
                    config.headers.Authorization = `Token ${token}`;
                }
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
        fetchEmails();
    }, []);

    const fetchEmails = () => {
        axios.get('/ghwdiotzii/backend/send-email/', {
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            setItems(response.data.map(item => ({ ...item, showMessage: false })));
        })
        .catch(error => {
            console.error('Es gab einen Fehler beim Abrufen der Daten:', error);
        });
    };

    const toggleMessage = (index) => {
        setItems(items.map((item, i) => 
            i === index ? { ...item, showMessage: !item.showMessage } : item
        ));
    };

    const delemail = (id) => {
        axios.delete(`/ghwdiotzii/backend/send-email/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            fetchEmails();
        })
        .catch(error => {
            console.error('Es gab einen Fehler beim Löschen der E-Mail:', error);
        });
    };

    if (!isLoggedIn) {
        return null;
    }

    return (
        <div>
            <Topbarprivat />
            <div className="App-header">
                <div className="img_header"><img src="/images/header_1.jpg" alt="Header" /></div>
                <div className="text_container" id="service-container">
                    <div className="text_container_data">
                        <div className="maillist">
                            <h2>Empfange Emails</h2>
                            {items.map((item, index) => (
                                <div className="mailitem" key={index}>
                                    <div className="mailitembar">
                                        <div className="mailitembar-email"><a>{item.email}</a></div>
                                        <div className="mailitembar-gender"><a>{item.gender}</a></div>
                                        <div className="mailitembar-name"><a>{item.name}</a></div>
                                        <div class="mailitembar-date"><a>{item.datetime}</a></div>
                                        <button onClick={() => toggleMessage(index)}>▼</button>
                                        <button onClick={(e) => { e.stopPropagation(); delemail(item.id); }}>Delete</button>
                                    </div>
                                    {item.showMessage && <div className="expandedMessage"><a>{item.message}</a></div>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmailView;
