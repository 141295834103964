import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import FrontPage from './components/frontpage/frontpage';
import Services from './components/services/services';
import Products from './components/products/products';
import Contact from './components/contact/contact';
import Aboutme from './components/aboutme/aboutme';
import Sidebar from './Sidebar';
import PC_app from './components/services/pc_app';
import Web_app from './components/services/web_app';
import Phone_app from './components/services/phone_app';
import Login from './components/loginarea/login';
import EmailView from './components/loginarea/emailview';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        {/* Sidebar hier einfügen */}
        <div className="content">
       
          <Routes>
            <Route path="/" element={<FrontPage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/application-software" element={<PC_app />} />
            <Route path="/services/web-development" element={<Web_app />} />
            <Route path="/services/mobile-applications" element={<Phone_app />} />
            <Route path="/products" element={<Products />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<Aboutme/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/privat/emailview" element={<EmailView/>} />
            {/* Weitere Routen hier hinzufügen */}
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;