import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import './aboutme.css';
import { Link } from 'react-router-dom'; 
import Sidebar from '../../Sidebar';
  
function Aboutme() {
    const [items, setItems] = useState([]);


  useEffect(() => {
    axios.get('/backend/home.php', {
      headers: {
          'Custom-type': 'about',
          'Content-Type': 'application/json',  // Beispiel für Content-Type
          // Weitere Header können hier hinzugefügt werden
      }
  })
  .then(response => {
      setItems(response.data);
  })
  .catch(error => {
      console.error('Es gab einen Fehler beim Abrufen der Daten:', error);
  });
  
  }
  , []);// Leer, d.h. dieser Effekt wird nur einmal nach dem initialen Rendern ausgeführt

  useEffect(() => {
      LanguageChange();
    }, [items]);// Dieser Effekt wird nur ausgeführt, wenn sich `items` ändert
  
    const LanguageChange = () => {
      if (items.length > 0) {
        const languageIndex = sessionStorage.getItem("language_index");
        const isEnglish = languageIndex != null && languageIndex == 1;
    
        const texts = ["text1","text2","text3"];
        const topics = ["topic1"];
        document.getElementById('age-topic').innerText= isEnglish ? "Age:":"Alter:";
        document.getElementById('age-value').innerText= isEnglish ? "25 years":"25 jahre";
        document.getElementById('spez-topic').innerText= isEnglish ? "Specialization:":"Spezialisierung:";
        document.getElementById('spez-value').innerText= isEnglish ? "Development of web applications and apps (backend and frontend)":"Entwicklung von Webanwendungen und Apps (Backend und Frontend)";
        document.getElementById('con-topic').innerText= isEnglish ? "Graduation:":"Abschluss:";
        document.getElementById('con-value').innerText= isEnglish ? "Bachelor of Science in Computer Science":"Bachelor of Science in der Informatik";
        document.getElementById('job-topic').innerText= isEnglish ? "Work experience:":"Berufserfahrung:";
        document.getElementById('job-value').innerText= isEnglish ? "6 years in software development":"6 Jahre in der Softwareentwicklung";
        document.getElementById('prog-topic').innerText= isEnglish ? "Programming language knowledge:":"Programmiersprachen Kenntnisse:";
        document.getElementById('frame-topic').innerText= isEnglish ? "Framework knowledge:":"Framework Kenntnisse:";
        texts.forEach((id, index) => {
          const element = document.getElementById(id);
          const topic_element = document.getElementById(topics[index]);
          if (element && items[index]) {
            element.innerHTML = isEnglish ? items[index].en_text : items[index].de_text;
          }
          if(topic_element){
              topic_element.innerHTML = isEnglish ? items[index].en_topic : items[index].de_topic;
          }
        });
      } else {
        document.getElementById('text1').innerHTML = "Error";
      }
    };
  return (
    <div><Sidebar onLanguageChange={LanguageChange}/> 
    <div class="App-header">
        <div class="img_header"><img src="/images/header_1.jpg"></img></div>
      <div class="text_container" id="service-container">
      <div class="text_container_data">
        <div class="about-me">
        <div class="aboutme-text">
          <div class="about-row"><a>Name:</a><a class="about-row-value">Finn Mikoschek</a></div>
          <div class="about-row"><a id="age-topic">Alter:</a><a class="about-row-value" id="age-value">25 Jahre</a></div>
          <div class="about-row"><a id="spez-topic">Spezialisierung:</a><a class="about-row-value" id="spez-value"> Entwicklung von Webanwendungen und Apps (Backend und Frontend)</a></div>
          <div class="about-row"><a id="con-topic">Abschluss:</a><a class="about-row-value" id="con-value"> Bachelor of Science in der Informatik</a></div>
          <div class="about-row"><a id="job-topic">Berufserfahrung:</a><a class="about-row-value" id="job-value">6 Jahre in der Softwareentwicklung</a></div>
          <div class="about-row"><a id="prog-topic">Programmiersprachen Kenntnisse:</a></div>
        <div class="tiles">
          <div class="tile" id='tile1'>C#</div>
          <div class="tile" id='tile2'>Javascript</div>
          <div class="tile" id='tile3'>Python</div>
          <div class="tile" id='tile4'>Java</div>
          <div class="tile" id='tile5'>C</div>
          <div class="tile" id='tile6'>Swift</div>
          <div class="tile" id='tile7'>Applescript</div>
          <div class="tile" id='tile8'>HTML</div>
          <div class="tile" id='tile9'>PHP</div>
          <div class="tile" id='tile10'>C++</div>
        </div>
        <div class="about-row"><a id="frame-topic">Framework Kenntnisse:</a></div>
        <div class="tiles">
          <div class="tile" id='tile3'>React</div>
          <div class="tile" id='tile6'>Django</div>
          <div class="tile" id='tile7'>.NET</div>

        </div>
        <div class="about-row"><a id="topic1">Über Mich:</a></div>
          <a id="text1"></a> 
          <a id="text2"></a>
          <a id="text3"></a>
        </div>
        <div class="aboutme-photo"><img src="/images/self.jpg"></img></div>
        </div>


      </div>
      </div>
    </div>
    </div>
  );
}

export default Aboutme;