import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import './contact.css';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom'; 
import Sidebar from '../../Sidebar';

  
function Contact() {
    const [items, setItems] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');
    const [gender, setGender] = useState('');
    const [captcha, setCaptcha] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (captcha.length==0) {
          setResponse('Bitte bestätigen Sie, dass Sie kein Roboter sind.');
          return;
      }
        try {
          const payload = {
            recaptcha_token: captcha,
            gender: gender,
            name: name,
            email: email,
            message: message,
          };
          const response = await fetch('/backend/sendemail.php/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          }
            );
            
            if (!response.ok) {
              const errorData = await response.json();
              setResponse(`Fehler: ${errorData.error}`);
              return;
          }
  
          const responseData = await response.json();
          setResponse(`Ihre E-Mail wurde erfolgreich versendet.`);
        } catch (error) {
            console.error('Request error:', error);  // Logge den gesamten Fehler
            setResponse(JSON.stringify(error));
        }
    };

    const handleCaptchaChange = (value) => {
      setCaptcha(value);
      
  };

  useEffect(() => {
    axios.get('/backend/home.php', {
      headers: {
          'Custom-type': 'contact',
          'Content-Type': 'application/json',  // Beispiel für Content-Type
          // Weitere Header können hier hinzugefügt werden
      }
  })
  .then(response => {
      setItems(response.data);
  })
  .catch(error => {
      console.error('Es gab einen Fehler beim Abrufen der Daten:', error);
  });
  
  }
  , []);// Leer, d.h. dieser Effekt wird nur einmal nach dem initialen Rendern ausgeführt

  useEffect(() => {
      LanguageChange();
    }, [items]);// Dieser Effekt wird nur ausgeführt, wenn sich `items` ändert
  
    const LanguageChange = () => {
      if (items.length > 0) {
        const languageIndex = sessionStorage.getItem("language_index");
        const isEnglish = languageIndex != null && languageIndex == 1;
    
        const texts = ["text1"];
        const topics = ["topic1"];
        document.getElementById('topic_header').innerText= isEnglish ? "Contact" : "Kontakt";
        document.getElementById('text_header').innerText=isEnglish ? "Please contact me if you have any questions.":"Bei Fragen kontaktieren Sie mich gerne.";
        document.getElementById('value0').innerText=isEnglish?"Salutation*":"Anrede*";
        document.getElementById('value1').innerText=isEnglish?"Sir":"Herr";
        document.getElementById('value2').innerText=isEnglish?"Madam":"Frau";
        document.getElementById('value3').innerText=isEnglish?"Diverse":"Divers";
        document.getElementById('contact-message').placeholder=isEnglish?"Your message to me*":"Ihre Nachricht an mich*";
        document.getElementById('header2').innerText=isEnglish?"Further contact options":"Weitere Kontakt möglichkeiten";
        document.getElementById('submit-button').innerText=isEnglish?"Send":"Senden";
        texts.forEach((id, index) => {
          const element = document.getElementById(id);
          const topic_element = document.getElementById(topics[index]);
          if (element && items[index]) {
            element.innerHTML = isEnglish ? items[index].en_text : items[index].de_text;
          }
          if(topic_element){
              topic_element.innerHTML = isEnglish ? items[index].en_topic : items[index].de_topic;
          }
        });
      } else {
        document.getElementById('text1').innerHTML = "Error";
      }
    };

  return (
    <div><Sidebar onLanguageChange={LanguageChange}/> 
    <div className="App-header">
      
        <div className="img_header"><h1 id='topic_header'>Kontakt</h1>
        <p id='text_header'>Bei Fragen kontakieren Sie uns gerne.</p><img src="/images/header_3.jpg" alt="header"></img></div>
      <div className="text_container" id="service-container">
      <div class="text_container_data">
        <h1 id="topic1">Kontaktieren Sie mich</h1>
        <a id="text1"></a>
        <div className="contact-container">
        <div className="contact-form-container">
        
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="Name-Email">
                <div className="Gender">
                <select value={gender} onChange={(e) => setGender(e.target.value)} required>
                    <option value="" id="value0" disabled>Anrede*</option>
                    <option value="Herr" id="value1" >Herr</option>
                    <option value="Frau" id="value2" >Frau</option>
                    <option value="Divers" id="value3" >Divers</option>
                </select>
            </div>
                    <input type="text" placeholder='Name*' value={name} onChange={(e) => setName(e.target.value)} required />
                    <input type="email" placeholder='E-mail*'  value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>

                <label>
                    <textarea id="contact-message" value={message} placeholder='Ihre Nachricht an uns' onChange={(e) => setMessage(e.target.value)} required />
                </label>
                <div className="captcha">
                        <ReCAPTCHA
                            sitekey="6LezdBAqAAAAAKHUDbzCx_g2zhPvkR5cUi_DjAB_"
                            onChange={handleCaptchaChange}
                        />
                    </div>
                {response && <p className="response-message">{response}</p>}
                <button type="submit" id="submit-button">Senden</button>
            </form>
        </div>
        <div className="tel-form-container">
          <p className="topic" id="header2">Weitere Kontakt möglichkeiten</p>
          <p><b>E-mail:</b> info@fim-software.de/info@finnmikoschek.de</p>
          <p><b>Tel:</b> +49 176 41571298</p>
        </div>
        </div>
      </div>
      </div>
    </div>
    </div>
  );
}

export default Contact;
