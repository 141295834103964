import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';

import { Link } from 'react-router-dom'; 
import Sidebar from '../../Sidebar';
  
function Products() {
    const [items, setItems] = useState([]);

  useEffect(() => {
    axios.get('/backend/home.php', {
      headers: {
          'Custom-type': 'product',
          'Content-Type': 'application/json',  // Beispiel für Content-Type
          // Weitere Header können hier hinzugefügt werden
      }
  })
  .then(response => {
      setItems(response.data);
  })
  .catch(error => {
      console.error('Es gab einen Fehler beim Abrufen der Daten:', error);
  });
  }, []);
    const LanguageChange = () =>{

    }
  return (
    <div><Sidebar onLanguageChange={LanguageChange}/> 
    <div class="App-header">
        <div class="img_header"><img src="/images/header_1.jpg"></img></div>
      <div class="text_container" id="service-container">
      <div class="text_container_data">
        <h1 id="first_header">Produkte</h1>
        
          {items.length > 0 ? (
            <a id="first_text">{items[0].de_text} </a>
            ) : (
            <a>Lädt...</a>
            )
          }

      </div>
      </div>
    </div>
    </div>
  );
}

export default Products;