import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import './loginarea.css'
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Sidebar';
import { login } from './auth';
function Login() {
    const [items, setItems] = useState([]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const user = await login(username, password);
            if(user!=undefined){
                navigate('/privat/emailview');
            }
            
            
        } catch (error) {
            console.error("Login failed", error);
        }
        
    };

    const LanguageChange = () =>{

    }
  return (
    <div><Sidebar onLanguageChange={LanguageChange}/> 
    <div class="App-header">
        <div class="img_header"><img src="/images/header_1.jpg"></img></div>
      <div class="text_container" id="service-container">
      <div class="text_container_data">
        
        <div class="login-field">
             
            <div class="login-field-data">

            <h1>Login</h1>
            <form  onSubmit={handleSubmit}>
                <input type="text" placeholder='Username'  value={username} onChange={(e) => setUsername(e.target.value)} required />
                <input type="password" placeholder='Password'  value={password} onChange={(e) => setPassword(e.target.value)} required />
                <button type="submit" id="submit-button">Login</button>
            </form>
            </div>
        </div>
      </div>
      </div>
    </div>
    </div>
  );
}

export default Login;