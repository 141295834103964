import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../sidebar.css';
import { logout} from './auth';
const Topbarprivat = () => {
  const navigate = useNavigate();
  const onClick = async (e) => {
    e.preventDefault();
    try {
        const user = await logout();
        console.log("Logged in user", user);
        navigate('/login');

    } catch (error) {
        console.error("Login failed", error);
    }
};

  return (
    <div>
    <div className="topbar">
      <div className="top_elements">
      <div className='left_topbar'>
          <div className="top_element">

          </div>
        </div>
        <div className='middle_topbar'>

        </div>
        <div className='right_topbar'>
        <div className="top_element">
        <div className="element_content">
              <a id="logout" onClick={onClick}>Logout</a>
        </div>
        </div>
      </div>
      </div>

      </div>
    </div>

  );
}

export default Topbarprivat;
